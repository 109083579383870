<template>
  <page-content :show-search="is_admin">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('geo_fencing.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />

    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'
import ModalForm from '@/components/ModalForm'

export default {
  name: 'GeoFencingReset',
  components: {
    ModalForm,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('geoFencingReset'),
      is_admin: common.isAdmin(),
      view_permission: common.checkPermission('GeoFencing_Menu_Reset'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'device_manage.mac',
          field: 'deviceMAC',
          width: '180px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value.toUpperCase()}</span>`
        },
        {
          label: 'geo_fencing.antenna_name',
          field: 'deviceName',
        },
        {
          label: 'device_reset.status',
          type: 'select',
          field: 'isDisarmed',
          sortable: false,
          style: 'width: 150px',
          width: '180px',
          options: [
            {
              text: common.getI18n('device_reset.status_preset'),
              value: ''
            },
            {
              text: common.getI18n('geo_fencing.reset_status_true'),
              value: true
            },
          ],
          selectedFun: this.statusSelected
        },
      ],
      header_actions: [],
      table_actions: [],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },

      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
    }
  },
  mounted() {
    common.getSearchCompanyOptions('companyID', this)
    common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
    common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    this.getList()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const params = Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      if (params.companyID && !params.facilityID) {
        params.facilityID = -1
      }
      this.$requestWehealth({
        method: 'get',
        url: '/geofencing/GetForceSettingList',
        params
      })
        .then(res => {
          this.total_rows = res.data.total
          let geoFencings = res.data.geoFencings
          for (const i in geoFencings) {
            if (geoFencings[i].isDisarmed == null) {
              geoFencings[i].isDisarmed = ''
            }
          }

          this.rows = JSON.parse(JSON.stringify(geoFencings))
        })

    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      action(data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    statusSelected: function (data) {
      console.log(data)
      this.$requestWehealth({
        method: 'post',
        url: '/geofencing/ForceSetting',
        data: {
          facilityID: common.getFacilityId(),
          geoFencingID: data.row.geoFencingID,
          isDisarmed: data.option.value === '' ? null : data.option.value,
        }
      })
        .finally(res => {
          this.getList()
        })

    }
  }
}
</script>
