<template>
  <div>
    <b-form>
      <b-row v-for="item in fields">
        <b-col cols="12">
          <b-form-group :label="item.label" label-cols="3">
            <template v-if="item.type === 'text'">
              <b-form-input type="text" v-model="modalData[item.name]"/>
            </template>

            <template v-else-if="item.type === 'password'">
              <b-form-input type="password" v-model="modalData[item.name]"/>
            </template>

            <template v-else-if="item.type === 'select'">
              <v-select
                label="text"
                :options="item.options"
                v-model="modalData[item.name]"
                :reduce="text => text.value"
                :clearable="false"
                :searchable="false"
              >
                <template #no-options>
                  {{$t('common.no_options')}}
                </template>
              </v-select>
            </template>

          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: "ModalForm",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  props: {
    fields: {type: Array},
    default: {type: Object}
  },
  data() {
    return {
      modalData: {}
    }
  },
  mounted() {
    if (this.default) {
      this.modalData = JSON.parse(JSON.stringify(this.default))
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
